import React from 'react'
import styled from 'react-emotion'

import Placeholder from "../assets/images/blocks-image.svg"

const Container = styled('div')({
  position: 'relative',
  margin: '0 auto',
  padding: '0 20px',
  marginTop: '100px',
  transition: 'all 0.6s ease',
  '@media only screen and (max-width: 700px)': {
    marginTop: '60px',
  }
})

const Image = styled('img')({
  width: '100%',
  overflow: 'hidden',
  transition: 'all 0.6s ease',
})

const ImageStack = () => (
  <Container>
    <Image src={Placeholder} alt="Placeholder" />
  </Container>
)

export default ImageStack
