import React from 'react'
import styled from 'react-emotion'

const ConentWrapper = styled('div')({
    margin: '100px auto',
    padding: '0 30px',
    '@media only screen and (max-width: 700px)': {
        marginTop: '30px'
    }
})

const BenefitsWrapper = styled('div')({
    maxWidth: '1100px',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '60px',
    marginTop: '60px',
    '@media only screen and (max-width: 700px)': {
        marginTop: '20px',
        gridTemplateColumns: '1fr',
    }
})

const Benefit = styled('div')({
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column',
})

const BenefitHeading = styled('div')({
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600
})

const BenefitParagraph = styled('div')({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#808080',
    marginTop: '10px'
})

const Heading = styled('h1')({
    fontSize: '42px',
    lineHeight: '40px',
    color: '#000000',
    fontWeight: '800',
    marginBottom: '10px',
    marginTop: '40px',
    '@media only screen and (max-width: 700px)': {
        fontSize: '34px',
        lineHeight: '36px',
        marginBottom: '20px'
    }
})

const Text = styled('div')({
    fontSize: '22px',
    color: '#808080',
    lineHeight: '26px',
    marginBottom: '40px',
    textAlign: 'left'
})

const HeaderWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})



const Benefits = () => (
    <ConentWrapper>
        <HeaderWrapper>
            <Heading>Boost personal productivity</Heading>
            <Text>We've got you covered with the tools you need to stay on top of things</Text>
        </HeaderWrapper>
        <BenefitsWrapper>
            <Benefit>
                <BenefitHeading>Get checklists out of your head and into blocks</BenefitHeading>
                <BenefitParagraph>
                    Arrange tasks into groups and simply drag & drop them around to organise your days, weeks and months.
                </BenefitParagraph>
            </Benefit>
            <Benefit>
                <BenefitHeading>Begin building your routines</BenefitHeading>
                <BenefitParagraph>
                    For things that you may need to do more than once or even regularly, conveniently schedule your tasks to repeat.
                </BenefitParagraph>
            </Benefit>
            <Benefit>
                <BenefitHeading>Stackable tasks keeping you up to date</BenefitHeading>
                <BenefitParagraph>
                    Unfinished tasks automatically get pushed to the next day so you can keep up with what was missed and get back on track.
                </BenefitParagraph>
            </Benefit>
            <Benefit>
                <BenefitHeading>Capture your future tasks for later</BenefitHeading>
                <BenefitParagraph>
                    Blocks is all about focusing on the present, but you can also add your future tasks into your backlog and bring them into view whenever you're ready.
                </BenefitParagraph>
            </Benefit>
        </BenefitsWrapper>
    </ConentWrapper>
)

export default Benefits
