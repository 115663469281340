import React from 'react'
import styled from 'react-emotion'

import BlockImage from "../assets/images/blocks.svg"

const Wrapper = styled('div')({
    position: 'relative',
    margin: '0 auto',
    marginTop: '-170px',
    width: 'fit-content',
    padding: '0 100px',
    '@media only screen and (max-width: 700px)': {
        marginTop: '-30px',
        padding: '0 40px'
    }
})

const Blocks = styled('img')({
    margin: '0 auto',
    height: 'auto',
    width: '100%',
    padding: '0 20px',
})

const Blockee = () => (
    <Wrapper>
        <Blocks src={BlockImage} />
    </Wrapper>
)

export default Blockee
