import React from 'react'
import { navigate } from "gatsby-link"

import styled from 'react-emotion'
import Icon from '../components/icon'
import Blockee from '../components/blockee'



const HeroWrapper = styled('div')({
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const TextWrapper = styled('div')({
  padding: '0 60px',
  margin: '0 auto',
  marginTop: '40px',
  maxWidth: '840px',
  '@media only screen and (max-width: 700px)': {
    padding: '0 23px'
  }
})

const MainHeading = styled('div')({
  fontSize: '62px',
  lineHeight: '60px',
  color: '#000000',
  fontWeight: '800',
  marginBottom: '40px',
  marginTop: '10px',
  '@media only screen and (max-width: 700px)': {
    fontSize: '34px',
    lineHeight: '30px',
    marginBottom: '20px'
  }
})

const Text = styled('div')({
  fontSize: '22px',
  color: '#808080',
  lineHeight: '28px',
  marginBottom: '40px'
})

const ButtonCTA = styled('div')({
  color: '#222',
  boxShadow: '6px 6px 0 #222',
  textAlign: 'left',
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: '600',
  backgroundColor: '#fff',
  border: 'solid #222 3px',
  display: 'flex',
  width: 'fit-content',
  height: 'fit-content',
  borderRadius: '5px',
  padding: '15px',
  cursor: 'pointer',
  position: 'relative',
  verticalAlign: 'middle',
  transition: 'all 0.3s ease',
  '@media only screen and (max-width: 700px)': {
    width: '100%',
    justifyContent: 'center',
    boxShadow: '0 0 0 #222'
  },
  '&:hover': {
    boxShadow: '0 0 0 #222',
    transition: 'all 0.3s ease',
    'img': {
      transform: 'rotate(-30deg)',
      transition: 'all 0.3s ease'
    },
  },
  'img': {
    marginRight: '10px',
    transform: 'rotate(0deg)',
    transition: 'all 0.3s ease'
  }
})


const Hero = () => (
  <HeroWrapper>
    <Blockee />
    <TextWrapper>
      <MainHeading>Blocks. The last personal task organiser you’ll ever need.</MainHeading>
      <Text>The perfect tool for the checklist-lover who juggles multiple tasks in the air at any given time. Quickly organise your tasks across multiple groups and schedule them with ease; all while clearly seeing what needs to get done.</Text>
      <ButtonCTA onClick={() => navigate('/signup/')}>
        <Icon name="key" />
        Request Early Access
      </ButtonCTA>
    </TextWrapper>
  </HeroWrapper>
)

export default Hero
