import React from 'react'
import styled from 'react-emotion'

import Key from "../assets/icons/key.svg"

const IconWrapper = styled('div')({
    margin: '0',
    'img': {
        marginBottom: 0
    }
})

const Icon = () => (
    <IconWrapper>
        <img alt="Key icon" src={Key} />
    </IconWrapper>
)

export default Icon